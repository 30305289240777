import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import ReactHtmlParser from "html-react-parser"

import article_icon from "../assets/img/ikonki/strony/artykul_white.svg"

import Layout from "../components/Layout/Layout"
import { LeftArrow, RightArrow } from "../components/Arrows/Arrows"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import { all_articles } from "../components/ArticlesList"

import HeaderImage from "../assets/img/new/tlo-serch-01.webp"
import { rootUrl } from "../components/Variables"
import i18n from "i18next"

const BazaWiedzy = data => {
  const articles = []
  const currentPaginationPage = data.pageContext.currentPage
  const [goToPage, setGoToPage] = useState(data.pageContext.currentPage)
  const currentPage = data.pageContext.currentPage - 1
  const limit = data.pageContext.limit
  const [nextPage, setNextPage] = useState(false)
  const [prevPage, setPrevPage] = useState(false)
  const [disablePrev, setDisablePrev] = useState(false)
  const [disableNext, setDisableNext] = useState(false)
  for (let i = 0; i < limit; i++) {
    if (all_articles?.[i + currentPage * limit]) {
      articles.push(all_articles[i + currentPage * limit])
    }
  }
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  useEffect(() => {
    if (currentPaginationPage <= 1) {
      setDisablePrev(true)
    }
    if (currentPaginationPage >= 5) {
      setDisableNext(true)
    }
    if (currentPaginationPage - 1 <= 1) {
      setPrevPage("/baza-wiedzy/artykuly")
    } else {
      setPrevPage("/baza-wiedzy/artykuly/" + (currentPaginationPage - 1))
    }
    setNextPage("/baza-wiedzy/artykuly/" + (currentPaginationPage + 1))
    // eslint-disable-next-line
  }, [])

  const isOdd = num => {
    return num % 2
  }

  const goToPageUpdate = e => {
    let newValue = e.target.value
    if (newValue === "") {
      setGoToPage("")
    } else {
      newValue = newValue.replace(/\D/g, "")
      if (newValue > 5) {
        setGoToPage(5)
      } else if (newValue < 1) {
        setGoToPage(1)
      } else {
        setGoToPage(newValue)
      }
    }
  }

  const paginationHandler = e => {
    e.preventDefault()
    if (goToPage <= 1) {
      navigate(`/baza-wiedzy/artykuly/`)
    } else {
      navigate(`/baza-wiedzy/artykuly/${goToPage}/`)
    }
  }

  return (
    <Layout
      seo={{
        title: "Optymalizacja SEO stron WWW • Skorzystaj z bazy wiedzy WeNet",
        description:
          "Chcesz dowiedzieć się więcej na temat optymalizacji SEO? • Skorzystaj z bazy wiedzy WeNet • Przeczytaj artykuły o SEO i optymalizacji stron WWW",
        hreflangs: [{ url: rootUrl + "/baza-wiedzy/artykuly/", lang: "pl" }],
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            currentPaginationPage !== 0
              ? {
                  "@type": "ListItem",
                  position: 3,
                  item: {
                    "@id": "/baza-wiedzy/artykuly/" + currentPaginationPage + "/",
                    name: "Baza wiedzy, strona " + currentPaginationPage,
                  },
                }
              : undefined,
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: "/", text: "Główna" },
              {
                url: "/baza-wiedzy/artykuly/",
                text: "Baza wiedzy / Artykuły",
              },
            ]}
          />
        </div>
        <h1 className="baza-wiedzy-title">Baza wiedzy - Artykuły</h1>
      </div>

      {articles.map((elem, index) => (
        <React.Fragment key={index}>
          {!isOdd(index) ? (
            <section className="baza-wiedzy-article text-image image-right reverse-flex">
              <div className="half">
                <div className="content">
                  <h2>
                    <Link to={elem.slug}>{ReactHtmlParser(elem.name)}</Link>
                  </h2>
                  <p className="article-excerpt">{ReactHtmlParser(elem.excerpt)}</p>
                  <Link to={elem.slug} className="btn btn-primary">
                    <img
                      src={article_icon}
                      alt={"Artykuł - ikonka"}
                      style={{ transform: "translateX(-8px) translateY(-1px)" }}
                    />{" "}
                    Czytaj więcej
                  </Link>
                </div>
              </div>
              <div
                className="half"
                style={{
                  background: `url(${elem.image}) center center no-repeat`,
                  backgroundSize: "cover",
                  position: "relative",
                }}
              >
                <Link
                  to={elem.slug}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></Link>
              </div>
            </section>
          ) : (
            <section className="baza-wiedzy-article text-image image-left">
              <div
                className="half"
                style={{
                  background: `url(${elem.image}) center center no-repeat`,
                  backgroundSize: "cover",
                  position: "relative",
                }}
              >
                <Link
                  to={elem.slug}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></Link>
              </div>
              <div className="half">
                <div className="content">
                  <h2 className="article-title">
                    <Link to={elem.slug}>{ReactHtmlParser(elem.name)}</Link>
                  </h2>
                  <p className="article-excerpt">{ReactHtmlParser(elem.excerpt)}</p>
                  <Link to={elem.slug} className="btn btn-primary">
                    <img
                      src={article_icon}
                      alt={"Artykuł - ikonka"}
                      style={{ transform: "translateX(-8px) translateY(-1px)" }}
                    />{" "}
                    Czytaj więcej
                  </Link>
                </div>
              </div>
            </section>
          )}
        </React.Fragment>
      ))}
      <div className="container">
        <div className="pagination">
          <form onSubmit={e => paginationHandler(e)}>
            <Link to={prevPage} className={disablePrev ? "disabled" : undefined}>
              <LeftArrow />
            </Link>
            <input type="text" value={goToPage} onChange={e => goToPageUpdate(e)} />
            <div className="max-pages"> / 5</div>
            <Link to={nextPage} className={disableNext ? "disabled" : undefined}>
              <RightArrow />
            </Link>
          </form>
        </div>
      </div>
      <footer className="go-to-blog-footer">
        <p className="bg-1">BLOG</p>
        <p className="bg-2">EKSPERCKI</p>
        <p className="fg-1">
          <span>
            Zajrzyj też koniecznie na{" "}
            <strong>
              <a href="https://wenet.pl/blog/" target="_blank" rel="noreferrer">
                bloga WeNet
              </a>
            </strong>
          </span>
          <br /> o SEO, SEM i e-marketingu i dowiedz się jeszcze więcej o optymalizacji i aktualnych wymogach Google.
        </p>
      </footer>
    </Layout>
  )
}

export default BazaWiedzy
