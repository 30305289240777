import React from "react"

export const LeftArrow = () => {
  return (
    <div className="arrow-wrapper arrow-left">
      <span></span>
      <span></span>
    </div>
  )
}

export const RightArrow = () => {
  return (
    <div className="arrow-wrapper arrow-right">
      <span></span>
      <span></span>
    </div>
  )
}
